import React, { useState } from 'react';
import Navigation from '../../Navigation';
import { MDBIcon } from 'mdbreact';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


const ForgotPassword = () => {

  const [email, setEmail] = useState('');
  const [emailCheck, setEmailCheck] = useState(true);
  const [otp, setOtp] = useState('');
  const [otpSend, setOtpSend] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [chngPwd, setChngPwd] = useState(false);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === '') {
      toast.warning('Enter Email Id');
    }
    else if (!/[a-z0-9]+@[a-z]+.(in|com|org)/.test(email)) {
      toast.info('Invalid Email Format');
    }
    else {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email
        }),
      });
      const result = await res.json();
      // console.log(result);
      if (res.status === 404) {
        return toast.error(result.msg);
      }
      else if (res.status === 500) {
        return toast.error(result.msg);
      }
      else if (res.status === 403) {
        return toast.error('Failed to send OTP!\nTry again later!!');
      }
      else if (res.status === 201) {
        setEmailCheck(false);
        setOtpSend(true);
      }
      else {
        toast.error('Something went wrong!\nTry again later!!');
      }
    }
  }

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    if (otp === '') {
      toast.info('Enter OTP');
    }
    else {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/verify-otp`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          otp,
          expiresIn: new Date().getTime()
        }),
      });
      const result = await res.json();
      // console.log(result);
      if (res.status === 408) {
        toast.error(result.msg);
        setEmailCheck(true);
        setOtpSend(false);
      }
      else if (res.status === 406) {
        toast.error(result.msg);
      }
      else if (res.status === 200) {
        setOtpSend(false);
        setChngPwd(true);
      }
      else {
        toast.error('Something went wrong!\nTry again later!!');
      }
    }
  }

  const handleSubmit3 = async (e) => {
    e.preventDefault();
    if (pwd === '' || pwd2 === '') {
      toast.warning('Fields can\'t be left empty!!');
    }
    else if (pwd !== pwd2) {
      toast.warning('Passwords did\'n matched!');
    }
    else if (pwd.length < 6)
      toast.warning('Password too short!!');
    else {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/update-pwd`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          pwd
        }),
      });

      const result = await res.json();
      if (res.status === 201) {
        toast.success(result.msg);
        history.push('/login');
      }
      else {
        toast.error(result.msg);
        history.push('/forgot-password');
      }
    }
  }

  return (
    <>
      <Navigation />
      <div className='inner'>

        <div className='text-center mb-3'>
          <MDBIcon icon="backspace" className='fa-3x' />
        </div>
        {emailCheck ?
          <Form>
            <h2>Reset Password</h2>
            <hr className='w-75' />
            <Form.Group className='mt-2'>
              <Form.Label>
                <MDBIcon icon="at" className='ml-1 mr-2' />
                Enter Email Address
              </Form.Label>
              <Form.Control name="email" type="email" value={email} onChange={(a) => setEmail(a.target.value)} placeholder="Enter Email Address to reset" />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={handleSubmit} className="w-50" size="sm" variant='dark' type="submit">
                Send OTP
              </Button>
            </Form.Group>
          </Form>
          : <div></div>}
        {otpSend ?
          <Form >
            <hr className='w-75' />
            <Form.Group className='mt-2'>
              <Form.Label>
                <MDBIcon far icon="check-circle" className='ml-1 mr-2' />
                Enter OTP
              </Form.Label>
              <Form.Control name="otp" type="otp" value={otp} onChange={(a) => setOtp(a.target.value)} placeholder="Enter OTP" />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={handleSubmit2} className="w-50" size="sm" variant='dark' type="submit">
                Verify OTP
              </Button>
            </Form.Group>
          </Form>
          : <div></div>}

        {chngPwd ?
          <Form >
            <hr className='w-75' />
            <Form.Group className='mt-2'>
              <Form.Label>
                <MDBIcon icon="key" className='ml-1 mr-2' />
                Enter New Password
              </Form.Label>
              <Form.Control name="pwd" type="password" value={pwd} onChange={(a) => setPwd(a.target.value)} placeholder="Enter New Password" />
            </Form.Group>
            <Form.Group className='mt-2'>
              <Form.Label>
                <MDBIcon icon="key" className='ml-1 mr-2' />
                Re-Enter New Password
              </Form.Label>
              <Form.Control name="pwd2" type="password" value={pwd2} onChange={(a) => setPwd2(a.target.value)} placeholder="Re-Enter New Password" />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={handleSubmit3} className="w-50" size="sm" variant='dark' type="submit">
                Change Password
              </Button>
            </Form.Group>
          </Form>
          : <div></div>}
      </div>
    </>
  );
}

export default ForgotPassword;