import React from 'react';
import logo from "../assets/logo.png";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink, useParams } from 'react-router-dom';

const SecondSidebar = () => {

	const params = useParams();      
  
	return (
		<div className="sidebar-fixed position-fixed">
			<a href="#!" className="logo-wrapper waves-effect">
				<img alt="Mentor India Logo" className="img-fluid" src={logo} />
			</a>
			<MDBListGroup className="list-group-flush">
				{/* <NavLink exact={true} to={`/dashboard`} activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="chart-pie" className="mr-3" />
						Dashboard
					</MDBListGroupItem>
				</NavLink> */}
				<NavLink to={`/user/${params.role}/${params.id}/assigned-students`} activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="user" className="mr-3" />
						Assigned Students
					</MDBListGroupItem>
				</NavLink>
				<hr className='w-100 my-2' />
				<NavLink to="/login" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="power-off" className='mr-3' />
						Logout
					</MDBListGroupItem>
				</NavLink>
			</MDBListGroup>
		</div>
	);
}

export default SecondSidebar;