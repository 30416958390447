import React from 'react';
import AdminCardSection1 from './sections/AdminCardSection1';
import BreadcrumSection from './sections/BreadcrumSection';
import SideNavigation from '../sideNavigation';


// // RazorPay Code
// const loadScript = (src) => {
//   return new Promise (resolve => {
//     const script = document.createElement('script');
//     script.src = src;
//     script.onload = () => {
//       resolve(true);
//     }
//     script.onerror = () => {
//       resolve(false);
//     }
//     document.body.appendChild(script);
//   })
// }

// const __DEV__ = document.domain === 'localhost';

const DashboardPage = () => {

  // const displayRazorpay = async () => {

  //   const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  //   if (!res) {
  //     toast("RazorPay access failed!");
  //     return;
  //   }

  //   try {
  //     const res = await fetch(`${process.env.REACT_APP_BASE_URL}/razorpay/razorpay`, {
  //       method: "POST",
  //       headers: {'Content-Type': 'application/json'}
  //     });
  //     const result = await res.json();
  //     // console.log(result);
  //     const options = {
  //       // "key": process.env.KEY_ID, 
  //       "key": process.env.REACT_APP_KEY_ID,
  //       "amount": result.amount,
  //       "currency": result.currency,
  //       "name": "Courses",
  //       "description": "Test Transaction...",
  //       "image": "https://example.com/your_logo",
  //       "order_id": result.id, 
  //       "handler": function (response) {
  //         toast(response.razorpay_payment_id);
  //         toast(response.razorpay_order_id);
  //         toast(response.razorpay_signature)
  //       },
  //       "prefill": {
  //         "name": "Bhavesh Kothari",
  //         "email": "knock.knock@example.com",
  //         "contact": "9999999999"
  //       },
  //     };
  //     var paymentObject = new window.Razorpay(options);
  //     paymentObject.on('payment.failed', function (response){
  //       toast.error(response.error.code);
  //       toast.error(response.error.description);
  //       toast.error(response.error.source);
  //       toast.error(response.error.step);
  //       toast.error(response.error.reason);
  //       toast.error(response.error.metadata.order_id);
  //       toast.error(response.error.metadata.payment_id);
  //     });
  //     paymentObject.open();
  //   } catch(err) {
  //     console.log(err);
  //   }
  // }

  return (
    <>
      <SideNavigation />
      <main id="content" className="p-5">
        <React.Fragment>
          <BreadcrumSection />
          <AdminCardSection1 />
        </React.Fragment>
        {/* <button onClick={displayRazorpay}>
          Buy Now!
        </button> */}
      </main>
    </>
  )
}

export default DashboardPage;