import React, { useState, useEffect } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import SecondSidebar from '../SecondSidebar';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

const AssignStudents = () => {

  const [users, setUser] = useState([]),
        [citystate, setCityState] = useState({}),
        [state, setState] = useState(""),
        [city, setCity] = useState(""),
        [cities, setCities] = useState([]),
        [classes, setClasses] = useState(0),
        [clear, setClear] = useState(false),
        [loading, setLoading] = useState(false);

  const params = useParams(),
        history = useHistory();

  // console.log(params);
  // Pagination
  const [listPerPage] = useState(10),
        [offset, setOffset] = useState(0),
        [pageCount, setPageCount] = useState(0);

  const loadCityState = async () => {
    try {
      const cs = await fetch('../../../data/cityState.json');
      const result = await cs.json();
      // console.log(result);
      setCityState(result);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    loadData();
  }

  const loadData = async () => {
    try {
      if (localStorage.getItem("jwttoken") === null)
        return history.push('/login');
      // console.log(localStorage.getItem("jwttoken"));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      if (params.role === "sales") {
        setLoading(true);
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/student/assigned-show/${params.id}`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            city,
            state,
            classes,
          }),
        });
        const result = await res.json();
        // console.log(result);
        setLoading(false);
        let limitResult = result.slice(offset*listPerPage, offset*listPerPage+listPerPage);
        setUser(limitResult);
        setPageCount(Math.ceil(result.length / listPerPage));
      }
      else if (params.role === "mentor") {
        setLoading(true);
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/paid/assigned-show/${params.id}`, {
          method: "POST",
          headers,
          body: JSON.stringify({
            city,
            state,
            classes,
          }),
        });
        const result = await res.json();
        // setUser(result);
        setLoading(false);
        let limitResult = result.slice(offset*listPerPage, offset*listPerPage+listPerPage);
        setUser(limitResult);
        setPageCount(Math.ceil(result.length / listPerPage));
      }
      else {
        history.push('/login');
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (e) => {
    setState(e.target.value);
    setCities(citystate[e.target.value]);
  }

  const handlePageClick = (e) => {
    setOffset(e.selected);
  }

  const load = () => {
    loadData();
  }

  const clearFilter = () => {
    setClasses(0);
    setCity("");
    setState("");
    setClear(true);
  }

  useEffect(() => {
    loadData();
  }, [clear]);

  useEffect(() => {
    load();
    loadCityState();
  }, [offset]);


  // Displaying Columns
  let num=0;
  let li = users.map((user) => (
    <tr key={++num}>
      <td>{num}</td>
      <td>{user.name}</td>
      <td>{user.classes}</td>
      <td>{user.email}</td>
      <td>{user.phn}</td>
      <td>{user.city}</td>
      <td>{user.state}</td>
    </tr>
  ));

  let sheher = [], states = [];
  // State Dropdown
  for (let key in citystate) {
    states.push(<option key={key} value={key}>{key}</option>);
  }

  // Cities Dropdown
  sheher = cities.map((c) => (
    <option key={c} value={c}>{c}</option>
  ));

  return (
    <>
      <SecondSidebar />
        <main id="content" className="p-5">

          <Form className="mb-4">
            <h2>Filters</h2>
            <Row>
              <Form.Group as={Col} md={4}>
                <Form.Label>Select Class</Form.Label><br />
                <Form.Select name="classes" value={classes} className='form-control-sm w-50' onChange={(e) => setClasses(e.target.value)}>
                  <option value={0}>Select Class</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label>Select State</Form.Label><br />
                <Form.Select name="state" value={state} className="form-control-sm w-50" onChange={handleChange}>
                  <option value="">Select State</option>
                  {states}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label>Select City</Form.Label><br />
                <Form.Select name="city" value={city} className="form-control-sm w-50" onChange={(a) => setCity(a.target.value)}>
                  <option value="">Select City</option>
                  {sheher}
                </Form.Select>
              </Form.Group>

            </Row>

            <Row>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={handleSubmit} className="" size="sm" variant='dark' type="submit">
                  Apply Filter
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={() => clearFilter()} className="" size="sm" variant='danger'>
                  Reset Filter
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <MDBTable hover className='text-center'>
            <MDBTableHead color="blue" textWhite>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Class</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>City</th>
                <th>State</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {(users.length === 0 && !loading)?<tr><td>No Data Available</td></tr>:li}
            </MDBTableBody>
          </MDBTable>

          {loading ?
          <div className='text-center mb-4'>
            <Spinner animation="border" />
          </div>:null}

          < ReactPaginate
            previousLabel={"<<" }
            nextLabel={ ">>" }
            breakLabel={ "..." }
            breakClassName={ "break-me" }
            pageCount={ pageCount }
            onPageChange={ handlePageClick }
            containerClassName={ "pagination justify-content-center" }
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={ "active bg-primary" }
          />

        </main>
    </>
  );
}

export default AssignStudents;