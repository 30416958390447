import React, { useState, useEffect } from 'react';
import SideNavigation from '../sideNavigation';
import { Button, Container, Row, Col, ListGroup, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import QAForm from './sections/QAForm';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import Question from './sections/Question';

const QA = () => {

  const [total, setTotal] = useState(0),
    [data, setData] = useState([]),
    [show, setShow] = useState(false),
    [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleShow = () => setShow(true),
    handleClose = () => setShow(false);


  const loadData = async () => {
    try {
      if (localStorage.getItem("jwttoken") === null)
        return history.push('/login');

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      setLoading(true);

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/qa/view-question`, {
        method: 'POST',
        headers
      });

      const result = await res.json();
      // console.log(result);
      setTotal(result.length);
      setData(result);
      setLoading(false);

    } catch (error) {
      console.log(error);
    }
  }

  const load = () => {
    loadData();
  }

  useEffect(() =>
    load()
    , []);


  // Managing Content
  let num = 0;
  let qa = data.map((question) => (
    <Question key={++num} question={question} num={num} load={load} />
  ));

  return (
    <>
      <MDBContainer>
        <MDBModal isOpen={show} toggle={handleShow} centered>
          <MDBModalHeader className='bg-primary text-white font-weight-normal' toggle={ handleClose }>
            Add Question
          </MDBModalHeader>

          <MDBModalBody>
            <QAForm />
          </MDBModalBody>

          <MDBModalFooter className='py-0'>
            <MDBBtn color="danger" size='sm' onClick={handleClose}>Close</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>

      <SideNavigation />
      
      <main id="content" className='p-5'>
        <Container>
          <Row>
            <Col xs={6}>
              <h1>Question: {total}</h1>
            </Col>
            <Col xs={6} className="text-right">
              <Button variant="success" size='md' onClick={handleShow}>Add Question</Button>
            </Col>
          </Row>
        </Container>

        <Container>
          <ListGroup variant="flush">
            {loading?
              <div className='text-center mt-5'>
                <Spinner style={{ width: '5rem', height: '5rem' }} animation="grow" />
              </div>:qa.length === 0?<div>No Questions Available</div> :qa
            }
          </ListGroup>
        </Container>
        
      </main>

    </>
  );
}

export default QA;
