import React, { useState } from 'react';
import { MDBInput } from 'mdbreact';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const QAForm = () => {

  const [question, setQuestion] = useState(''),
    [a, setA] = useState(''),
    [b, setB] = useState(''),
    [c, setC] = useState(''),
    [d, setD] = useState(''),
    [crct, setCrct] = useState(''),
    history = useHistory();

  const addQuestion = (e) => {
    e.preventDefault();
    if (question === '')
      toast.warning('Question field can\'t be left empty!');
    else if (a === '' || b === '')
      toast.warning("Atleast Options A & B Required!");
    else if (crct === '')
      toast.warning('Select a Correct Option!');
    else
      uploadQuestion();
  }

  const uploadQuestion = async () => {
    try {

      let options = [];

      if (c === '' || d === '') {
        options = [
          {
            value: a,
            isTrue: (crct === 'A' ? true : false)
          },
          {
            value: b,
            isTrue: (crct === 'B' ? true : false)
          }
        ]
      }
      else {
        options = [
          {
            value: a,
            isTrue: (crct === 'A' ? true : false)
          },
          {
            value: b,
            isTrue: (crct === 'B' ? true : false)
          },
          {
            value: c,
            isTrue: (crct === 'C' ? true : false)
          },
          {
            value: d,
            isTrue: (crct === 'D' ? true : false)
          }
        ]
      }

      if (localStorage.getItem("jwttoken") === null)
        return history.push('/login');
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/qa/add-question`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          question,
          options
        }),
      });
      await res.json();
      window.location.reload(false);
      // console.log(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Form as={Container}>

        <h3 className='m-0 text-decoration-underline d-inline font-weight-normal'>Question : </h3>

        <Row>
          <Form.Group as={Col}>
            <MDBInput type="text" name="question" value={question} onChange={(e) => setQuestion(e.target.value)} label="Question" icon="question-circle" />
          </Form.Group>
        </Row>

        <h4 className='m-0 text-decoration-underline d-inline font-weight-normal'>Options :</h4>

        <Row>
          <Form.Group as={Col} xs='12' sm='6'>
            <MDBInput type="text" name="a" value={a} onChange={(e) => setA(e.target.value)} label="Option A" icon="" />
          </Form.Group>
          <Form.Group as={Col} xs='12' sm='6'>
            <MDBInput type="text" name="b" value={b} onChange={(e) => setB(e.target.value)} label="Option B" icon="" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} xs='12' sm='6'>
            <MDBInput type="text" name="c" value={c} onChange={(e) => setC(e.target.value)} label="Option C" icon="" />
          </Form.Group>
          <Form.Group as={Col} xs='12' sm='6'>
            <MDBInput type="text" name="d" value={d} onChange={(e) => setD(e.target.value)} label="Option D" icon="" />
          </Form.Group>
        </Row>

        <Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>Correct Answer</Form.Label> <br />
            <Form.Select value={crct} name='crct' onChange={(e) => setCrct(e.target.value)} className='form-control-sm w-75'>
              <option value={''} disabled>Correct Option</option>
              <option value={'A'}>A</option>
              <option value={'B'}>B</option>
              <option value={'C'}>C</option>
              <option value={'D'}>D</option>
            </Form.Select>
          </Form.Group>
        </Row>

        <Row className='justify-content-end'>
          <Button variant="success" size='sm' onClick={addQuestion}>Add Question</Button>
        </Row>
      </Form>
    </>
  );
}

export default QAForm;