import React, { useState, useEffect } from 'react';
import SideNavigation from '../sideNavigation';
import { Form, Col, Row, Button, Spinner } from 'react-bootstrap';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';


const ActivityStatus = () => {

  let date = new Date();
  date = date.setDate(date.getDate()+1);

  const history = useHistory(),
        [sdate, setSdate] = useState(new Date(2022, 1, 15, 0, 0, 0).toISOString().substring(0, 10)),
        [edate, setEdate] = useState(new Date(date).toISOString().substring(0, 10)),
        [name, setName] = useState(''),
        [list, setList] = useState([]);

  // Pagination
  const [listPerPage] = useState(9),
        [offset, setOffset] = useState(0),
        [pageCount, setPageCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const [clear, setClear] = useState(false);

  const loadData = async () => {
    try {
      if (localStorage.getItem("jwttoken") === null)
          return history.push('/login');
        // console.log(localStorage.getItem("jwttoken"));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      setLoading(true);

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/timeout/get-activity`,{
        method: 'POST',
        headers,
        body: JSON.stringify({
          sdate,
          edate,
          name,
        })
      });
      const result = await res.json();

      setLoading(false);

      let limitResult = result.slice(offset*listPerPage, offset*listPerPage+listPerPage);
      setList(limitResult);
      setPageCount(Math.ceil(result.length / listPerPage));
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    loadData();
  }

  const load = () => {
    loadData();
  }

  const handlePageClick = (e) => {
    setOffset(e.selected);
  }

  const clearFilter = () => {
    setName("");
    setEdate(new Date(date).toISOString().substring(0, 10));
    setSdate(new Date(2022, 1, 15, 0, 0, 0).toISOString().substring(0, 10));
    setClear(true);
  }

  useEffect(() => {
    loadData();
  }, [clear]);

  useEffect( () =>
    load()
  , [offset]);

  let num = 0;
  const data = list.map((ek) => (
    <tr key={++num}>
      <td>{num}</td>
      <td>{ek.name}</td>
      <td>{ek.role}</td>
      <td>{ek.logindate}</td>
      <td>{ek.logintime}</td>
      <td>{ek.logoutdate}</td>
      <td>{ek.logouttime}</td>
      <td>{!isNaN(ek.totaltime)?Math.floor(ek.totaltime):"*"}</td>
      <td>{ek.browser}</td>
      <td>{ek.ipaddress}</td>
    </tr>
  ));

  return (
    <>
      <SideNavigation />
      <main id="content" className='p-5'>
        <Form className='mb-4'>
          <h2>Filters</h2>
          <Row>
            <Form.Group as={Col} md={4}>
              <Form.Label>Select Start Date</Form.Label>
              <Form.Control type="date" name="sdate" value={sdate} onChange={(e) => setSdate(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4}>
              <Form.Label>Select End Date</Form.Label>
              <Form.Control type="date" name="edate" value={edate} onChange={(e) => setEdate(e.target.value)} />
            </Form.Group>

          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="mt-2">
              <Form.Label>Full Name</Form.Label>
              <Form.Control type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>

            <Form.Group as={Col} md={4} className="mt-2 align-self-end">
              <Button onClick={handleSubmit} className="" size="sm" variant='dark' type="submit">
                Apply Filter
              </Button>
            </Form.Group>

            <Form.Group as={Col} md={4} className="mt-2 align-self-end">
              <Button onClick={clearFilter} className="" size="sm" variant='danger'>
                Reset Filter
              </Button>
            </Form.Group>

          </Row>
        </Form>

        <MDBTable hover className='text-center' responsive>

          <MDBTableHead color="blue" textWhite>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Role</th>
              <th>Login Date</th>
              <th>Login Time</th>
              <th>Logout Date</th>
              <th>Logout Time</th>
              <th>Total Duration (in min)</th>
              <th>Browser</th>
              <th>IP Address</th>
            </tr>
          </MDBTableHead>

          <MDBTableBody>
            {(list.length === 0 && !loading)?<tr><td>No User Activity!</td></tr>:data}
          </MDBTableBody>

        </MDBTable>

        
        {loading ?
          <div className='text-center mb-4'>
            <Spinner animation="border" />
          </div>:null}

        < ReactPaginate
          previousLabel={"<<" }
          nextLabel={ ">>" }
          breakLabel={ "..." }
          breakClassName={ "break-me" }
          pageCount={ pageCount }
          onPageChange={ handlePageClick }
          containerClassName={ "pagination justify-content-center" }
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          nextClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          activeClassName={ "active bg-primary" }
        />
      </main>
    </>
  );
}

export default ActivityStatus;