import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { MDBIcon } from "mdbreact";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';

const Question = ({ question, num, load }) => {

  // console.log(question);
  const history = useHistory();

  const deleteQuestion = async () => {
    try {
      if (localStorage.getItem("jwttoken") === null)
        return history.push('/login');
      // console.log(localStorage.getItem("jwttoken"));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }
      await fetch(`${process.env.REACT_APP_BASE_URL}/qa/delete-question/${question._id}`, {
        method: 'DELETE',
        headers
      });
      toast.success('Question Removed!!');
      load();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <ListGroup.Item className='h4'>
        <Row>
          <Col md={9}>
            {`${num}. ${question.question}`}
          </Col>
          <Col md={3} className='text-right'>
            <Button onClick={deleteQuestion} variant='danger' className="py-1 px-3">
              <MDBIcon far icon="trash-alt" />
            </Button>
          </Col>
        </Row>

        <ListGroup className='h6'>
          <ListGroup.Item>
            {`A. ${question.options[0].value}`}
          </ListGroup.Item>
          <ListGroup.Item>
            {`B. ${question.options[1].value}`}
          </ListGroup.Item>
          {!question.options[2] ? <></> : <ListGroup.Item>
            {`C. ${question.options[2].value}`}
          </ListGroup.Item>}
          {!question.options[3] ? <></> : <ListGroup.Item>
            {`D. ${question.options[3].value}`}
          </ListGroup.Item>}
        </ListGroup>
      </ListGroup.Item>
    </>
  );
}

export default Question;