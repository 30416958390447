import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import Navigation from './Navigation';
import { MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      pwd: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
    // console.log(`${name}: ${value}`);
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      email,
      pwd
    } = this.state;
    if (email === '' || pwd === '') {
      // alert('All Fields Required!');
      toast.error('All Fields Required!');
    }
    else
      this.loadData();
  }

  componentDidMount() {
    if (localStorage.getItem("jwttoken") !== null || localStorage.getItem("_id") !== null) {
      this.logout();
    }
  }

  async logout () {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/timeout/logout/${localStorage.getItem('_id')}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      });
      await res.json();
      localStorage.removeItem("jwttoken");
      localStorage.removeItem("_id");
    } catch (err) {
      console.log(err);
    }
  }

  async loadData() {
    try {
      const { email, pwd } = this.state;

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/sign-in`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          pwd
        }),
      });
      const result = await res.json();
      // console.log(result);
      if (res.status === 401) {
        // alert('Invalid Credentials!');
        toast.error('Invalid Credentials!');
      }
      else if (res.status === 404) {
        // alert('User Doesn\'t Exists!');
        toast.error('User Doesn\'t Exists!');
      }
      else if (res.status === 200) {
        localStorage.setItem("jwttoken", result.token);
        localStorage.setItem("_id", result.s._id);
        if (result.user.role === "admin")  
          this.props.history.push('/dashboard');
        else if (result.user.role === "sales" || result.user.role === "mentor")
          this.props.history.push({
            pathname: `/user/${result.user.role}/${result.user._id}/assigned-students`,
            state: result.user
          });
        // alert('Signed In!');
      }
      else {
        console.log(result);
        toast.error('An Error Occured!');
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>
        <Navigation />
        <div className='inner mb-5'>
          <div style={{paddingLeft: "2.3rem"}} className='text-center mb-4'>
            <MDBIcon icon="user-check" className='fa-6x' />
          </div>
          <Form>
            <h2>Log In</h2>
            <hr className='w-75' />
            <Form.Group className='mt-2'>
              <Form.Label>
                <MDBIcon icon="at" className='ml-1 mr-2' />
                Email Account
              </Form.Label>
              <Form.Control name="email" type="email" value={this.state.value} onChange={this.handleChange} placeholder="Enter Company Mail Id" />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                <MDBIcon icon="key" className='ml-1 mr-2' />
                Password
              </Form.Label>
              <Form.Control name="pwd" type="password" value={this.state.value} onChange={this.handleChange} placeholder="Enter password" />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={this.handleSubmit} className="w-50" size="sm" variant='dark' type="submit">
                Sign In
              </Button>
            </Form.Group>
          </Form>
          <div className='text-right text-primary mt-4 upar'>
            <NavLink to="/forgot-password" activeClassName='activeClass'>
              Forgot Password?
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

export default Login;