import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';

export default class DropDownRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      role: this.props.sale.role,
      _id: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      role: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.assignData();
  }

  componentDidMount() {
    this.setState({
      role: this.props.sale.role
    });
  }

  async assignData() {
    const {
      role,
      _id
    } = this.state;
    if (localStorage.getItem("jwttoken") === null)
      return this.props.history.push('/login');
    // console.log(localStorage.getItem("jwttoken"));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/changeRole/${_id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ role }),
      });
      const result = await res.json();
      // console.log(result);
      if (result && res.status === 201) {
        toast.success(result.msg)
        this.props.loadData();
      }
      else {
        toast.error(result.msg);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async deleteUser(_id) {
    if (localStorage.getItem("jwttoken") === null)
      return this.props.history.push('/login');
    // console.log(localStorage.getItem("jwttoken"));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
    }
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/user/del-user/${_id}`, {
        method: 'DELETE',
        headers
      });
      toast.info('Employee Removed!!');
      this.props.loadData();
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {

    const {
      sale,
      roles
    } = this.props;

    return (
      <>
        <tr>
          <td>{this.props.num}</td>
          <td>{sale.name}</td>
          <td>{sale.email}</td>
          {/* <td>{sale.createdAt}</td> */}
          <td className='py-2'>
            <Form onSubmit={this.handleSubmit}>
              <Form.Select aria-label="List" name="assignto" value={this.state.assignto} onChange={this.handleChange} className='form-control-sm'>
                <option value={this.state.role}>{this.state.role === "sales" ? "Sales" : "Mentor"}</option>
                {roles}
              </Form.Select>
              <Button variant="success" onClick={() => this.setState({ _id: sale._id })} type="submit" size='sm'>
                Change
              </Button>
            </Form>
          </td>
          <td className='py-2'>
            <Button className='btn btn-sm btn-danger' onClick={() => this.deleteUser(sale._id)}>
              <MDBIcon icon="user-times" className="" />
            </Button>
          </td>
        </tr>
      </>
    );
  }
}