import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import AllUsers from './pages/AllUsers';
import AllOrders from './pages/AllOrders';
import Sales from './pages/Sales';
import Mentors from './pages/Mentor';
import Login from './Login';
import Register from './Register';
import AssignStudents from './pages/AssignStudents';
import ForgotPassword from './pages/sections/ForgetPassword';
import ActivityStatus from './pages/ActivityStatus';
import QA from './pages/QA';

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path='/' exact component={Login} />
        <Route path='/login' component={Login} />
        <Route path='/register' component={Register} />
        <Route path='/dashboard' component={DashboardPage} />
        <Route path='/all-users' component={AllUsers} />
        <Route path='/all-orders' component={AllOrders} />
        <Route path='/sales' component={Sales} />
        <Route path='/mentors' component={Mentors} />
        <Route path='/user/:role/:id/assigned-students' component={AssignStudents} />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/activity-list' component={ActivityStatus} />
        <Route path='/question' component={QA} />
      </Switch>
    );
  }
}

export default Routes;
