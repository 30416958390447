import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';

export default class DropDownRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      assignto: this.props.user.assignto,
      _id: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    console.log(e.target.value);
    this.setState({
      assignto: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.assignData();
  }

  componentDidMount() {
    this.setState({
      assignto: this.props.user.assignto
    });
  }

  async assignData() {
    const {
      assignto,
      _id
    } = this.state;



    if (localStorage.getItem("jwttoken") === null)
      return this.props.history.push('/login');
    // console.log(localStorage.getItem("jwttoken"));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
    }
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/${this.props.api}/assignSales/${_id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ assignto }),
      });
      const result = await res.json();
      // console.log(result);
      if (result && res.status === 201) {
        toast.success('Sales Person/Mentor Assigned!')
        this.props.loadData();
      }
      else {
        toast.error('An error occured!\nTry again later');
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  async deleteUser(_id) {
    if (localStorage.getItem("jwttoken") === null)
      return this.props.history.push('/login');
    // console.log(localStorage.getItem("jwttoken"));
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
    }
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/${this.props.api}/del-user/${_id}`, {
        method: 'DELETE',
        headers
      });
      toast.info('Student Removed!!');
      this.props.loadData();
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {

    const {
      user,
      sales
    } = this.props;



    return (
      <>
        <tr>
          <td>{this.props.num}</td>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.phn}</td>
          <td>{user.classes}</td>
          <td>{user.city}</td>
          <td>{user.state}</td>
          <td className='py-2'>
            <Form onSubmit={this.handleSubmit}>
              <Form.Select aria-label="List" name="assignto" value={this.state.assignto} onChange={this.handleChange} className='form-control-sm'>
                <option value={0}>--Not Selected--</option>
                {sales}
              </Form.Select>
              <Button variant="primary" onClick={() => this.setState({ _id: user._id })} type="submit" size='sm'>
                Assign
              </Button>
            </Form>
          </td>
          <td className='py-2'>
            <Button className='btn btn-sm btn-danger' onClick={() => this.deleteUser(user._id)}>
              <MDBIcon icon="user-times" className="" />
            </Button>
          </td>
        </tr>
      </>
    );
  }
}