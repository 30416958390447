import React from 'react';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import SideNavigation from '../sideNavigation';
import DropDownRow from './sections/DropDownRow';
import ReactPaginate from 'react-paginate';

class AllOrders extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      mentor: [],
      citystate: {},
      state: "",
      city: "",
      cities: [],
      classes: 0,
      listPerPage: 8,
      offset: 0,
      pagecount: 0,
      loading: false,
      clear: true
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.load = this.load.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  handleChange(e) {
    const name = e.target.name,
          value = e.target.value;
    this.setState({
      [name]: value
    });
    if (name === "state") {
      this.setState({
        cities: this.state.citystate[value]
      }); 
    }
  }

  handlePageClick(e) {
    this.setState({
      offset: e.selected
    });
    this.loadData();
  }

  async loadCityState() {
    try {
      const cityState = await fetch('data/cityState.json');
      const result = await cityState.json();
      // console.log(result);
      this.setState({
        citystate: result
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }

  async loadData() {
    try {
      const {
        classes,
        city,
        state
      } = this.state;
      if (localStorage.getItem("jwttoken") === null)
        return this.props.history.push('/login');
      // console.log(localStorage.getItem("jwttoken"));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      this.setState({
        loading: true
      });

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/paid/get-students`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          classes,
          city,
          state
        }),
      });
      const result = await res.json();
      // console.log(result);
      let limitResult = result.slice(this.state.offset*this.state.listPerPage, this.state.offset*this.state.listPerPage+this.state.listPerPage);

      const res2 = await fetch(`${process.env.REACT_APP_BASE_URL}/user/mentor`, {
        method: 'POST',
        headers
      });
      const result2 = await res2.json();
      
      this.setState({
        loading: false,
        orders: limitResult,
        mentor: result2,
        pagecount: Math.ceil(result.length / this.state.listPerPage)
      });
      // console.log(result2);
    }
    catch (err) {
      console.log(err);
    }
  }

  load() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
    this.loadCityState();
  }

  clearFilter() {
    this.setState({
      classes: 0,
      city: "",
      state: "",
      clear: false
    }, () => this.loadData());
  }



  render() {

    let num = 0;

    let orders = [], mentor = [], states = [], cities = [];

    try {

      // Mentors Dropdown
      mentor = this.state.mentor.map((ment) => (
        <option key={ment.id} value={ment._id}>{ment.name}</option>
      ));

      // Paid Students List
      orders = this.state.orders.map((order) => (
        <DropDownRow loadData={this.load} api={"paid"} key={order._id} num={++num} user={order} sales={mentor} />
      ));

      // console.log(this.state.orders);

      // State Dropdown
      for (let key in this.state.citystate) {
        states.push(<option key={key} value={key}>{key}</option>);
      }

      // Cities Dropdown
      cities = this.state.cities.map((city) => (
        <option key={city} value={city}>{city}</option>
      ));

    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <SideNavigation />
        <main id="content" className="p-5">

          <Form className='mb-4'>
            <h2>Filters</h2>
            <Row>
              <Form.Group as={Col} md={4}>
                <Form.Label>Select Class</Form.Label><br />
                <Form.Select name="classes" value={this.state.classes} className='form-control-sm w-50' onChange={this.handleChange}>
                  <option value={0}>Select Class</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label>Select State</Form.Label><br />
                <Form.Select name="state" value={this.state.state} className="form-control-sm w-50" onChange={this.handleChange}>
                  <option value="">Select State</option>
                  {states}
                </Form.Select>
              </Form.Group>
              
              <Form.Group as={Col} md={4}>
                <Form.Label>Select City</Form.Label><br />
                <Form.Select name="city" value={this.state.city} className="form-control-sm w-50" onChange={this.handleChange}>
                  <option value="">Select City</option>
                  {cities}
                </Form.Select>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={this.handleSubmit} className="" size="sm" variant='dark' type="submit">
                  Apply Filter
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={this.clearFilter} className="" size="sm" variant='danger'>
                  Reset Filter
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <MDBTable className='text-center' hover striped responsive>

            <MDBTableHead color="primary-color" textWhite>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Class</th>
                <th>City</th>
                <th>State</th>
                <th>Mentor</th>
                <th>Remove Student</th>
              </tr>
            </MDBTableHead>

            <MDBTableBody>
              {(this.state.orders.length === 0 && !this.state.loading)?<tr><td>No Data Found</td></tr>:orders}
            </MDBTableBody>

          </MDBTable>

          {this.state.loading?
          <div className='text-center mb-3'>
            <Spinner animation="border" />
          </div>:null}

          < ReactPaginate
            previousLabel={"<<" }
            nextLabel={ ">>" }
            breakLabel={ "..." }
            breakClassName={ "break-me" }
            pageCount={ this.state.pagecount }
            onPageChange={ this.handlePageClick }
            containerClassName={ "pagination justify-content-center" }
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={ "active bg-primary" }
          />
        </main>
      </>
    );
  }
}

export default AllOrders;