import React from 'react';
import logo from "../assets/logo.png";
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';

const SideNavigation = () => {

	return (
		<div className="sidebar-fixed position-fixed">
			<a href="#!" className="logo-wrapper waves-effect">
				<img alt="Mentor India Logo" className="img-fluid" src={logo} />
			</a>
			<MDBListGroup className="list-group-flush">
				<NavLink exact={true} to="/dashboard" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="chart-pie" className="mr-3" />
						Dashboard
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/all-users" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="user" className="mr-3" />
						All Users
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/all-orders" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="cart-plus" className="mr-3" />
						All Orders
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/sales" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="poll-h" className="mr-3" />
						Sales
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/mentors" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="user-graduate" className="mr-3" />
						Mentors
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/activity-list" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="clock" className="mr-3" />
						Activity Status
					</MDBListGroupItem>
				</NavLink>
				<NavLink to="/question" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="question-circle" className="mr-3" />
						Q &amp; A
					</MDBListGroupItem>
				</NavLink>
				<hr className='w-100 my-2' />
				<NavLink to="/login" activeClassName="activeClass">
					<MDBListGroupItem>
						<MDBIcon icon="power-off" className='mr-3' />
						Logout
					</MDBListGroupItem>
				</NavLink>
			</MDBListGroup>
		</div>
	);
}

export default SideNavigation;