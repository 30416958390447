import React, { Component } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import Navigation from './Navigation';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';


class Register extends Component {

  constructor(props) {
    super(props);

    // console.log(process.env);

    this.state = {
      name: '',
      email: '',
      pwd: '',
      pwd2: '',
      role: '',
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {
      name,
      email,
      pwd,
      pwd2,
      role
    } = this.state;
    console.log(name);
    if (name === '' || email === '' || pwd === '' || pwd2 === '' || role === '')
      toast.warning('Fill all fields!');
    else if (!/[a-z0-9]+@[a-z]+.(in|com|org)/.test(email))
      toast.info('Improper Email format!');
    else if (pwd !== pwd2)
      toast.info('Passwords didn\'t match!');
    else if (pwd.length < 6)
      toast.warning('Password too short!!');
    else
      this.sendData();
  }

  async sendData() {
    const {
      name,
      email,
      pwd,
      role
    } = this.state;

    this.setState({
      loading: true
    });

    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/add-person`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name,
          email,
          pwd,
          role
        }),
      });
      const result = await res.json();
      // console.log(result);
      this.setState({
        loading: false
      });
      if (res.status === 400)
        toast.error(result.msg);
      else if (res.status === 406) {
        toast.error (result.msg);
        this.setState({
          name: '',
          email: '',
          pwd: '',
          pwd2: '',
          role: ''
        });
      }
      else if (res.status === 201) {
        toast.success('User Created');
        this.setState({
          name: '',
          email: '',
          pwd: '',
          pwd2: '',
          role: ''
        });
      }
      else {
        toast.error('An Error Occured!!');
      }
      // location.reload();
    }
    catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <>

        <Modal show={this.state.loading} animation={false} centered>
          <Modal.Body className='text-center my-5'>
            <Spinner animation="border" style={{ width: '5rem', height: '5rem' }} />
          </Modal.Body>
        </Modal>

        <Navigation />
      
        <div className='inner mb-5'>
          <div className='text-center mb-4 pl-3'>
            <MDBIcon icon="user-plus" className='fa-6x' />
          </div>
          <Form>
            <h2>Registration Form</h2>
            <hr className='w-75' />
            <Form.Group>
              <Form.Label>
                <MDBIcon icon="signature" className='ml-1 mr-2' />
                Full Name
              </Form.Label>
              <Form.Control name="name" type="text" value={this.state.name} onChange={this.handleChange} placeholder="Full Name" required />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                <MDBIcon icon="pencil-alt" className='ml-1 mr-2' />
                Role
              </Form.Label>
              <br />
              <Form.Check
                type="radio" className="d-inline" name="role" label="Sales Person" onChange={this.handleChange} value="sales" required
              />
              <Form.Check
                type="radio" className="d-inline ml-4" name="role" label="Mentor" onChange={this.handleChange} value="mentor"
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                <MDBIcon icon="at" className='ml-1 mr-2' />
                Email ID
              </Form.Label>
              <Form.Control name="email" type="email" value={this.state.email} onChange={this.handleChange} placeholder="Email ID" required />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                <MDBIcon icon="shield-alt" className='ml-1 mr-2' />
                Password
              </Form.Label>
              <Form.Control name="pwd" type="password" value={this.state.pwd} onChange={this.handleChange} placeholder="Enter password" required />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>
                <MDBIcon icon="shield-alt" className='ml-1 mr-2' />
                Confirm Password
              </Form.Label>
              <Form.Control name="pwd2" type="password" value={this.state.pwd2} onChange={this.handleChange} placeholder="Re-Enter password" required />
            </Form.Group>
            <Form.Group className="text-center mt-4">
              <Button onClick={this.handleSubmit} className="w-50" size="sm" variant="dark" type="submit">
                Sign Up
              </Button>
            </Form.Group>
          </Form>
        </div>
      </>
    );
  }
}

export default Register;