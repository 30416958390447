import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../assets/logo.png';
import { MDBIcon } from 'mdbreact';

class Navigation extends React.Component {

  render() {
    return (
      <>
        <div>
          <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark" className="mb-4 mb-md-5">
            <Container>
              <Navbar.Brand id="head" href="/">
                <img
                  alt="Logo"
                  src={logo}
                  width="70"
                  height="30"
                  className="d-inline-block align-top mr-2"
                />
                Mentoring India
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className='justify-content-end'>
                <Nav className="me-auto">
                  <Nav.Link className="links" href="/login">
                    <MDBIcon icon="sign-in-alt" className='mr-3 mr-md-2' />
                    Login
                  </Nav.Link>
                  <Nav.Link className="links" href="/register">
                    <MDBIcon icon="plus" className='mr-3 mr-md-2' />
                    Register
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </>
    );
  };
}

export default Navigation;