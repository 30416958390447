import React from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';
import SideNavigation from '../sideNavigation';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import SalesMentorRow from './sections/SalesMentorRow';
import ReactPaginate from 'react-paginate';

class Sales extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      sales: [],
      sdate: new Date(2022, 1, 15, 0, 0, 0).toISOString().substring(0, 10),
      edate: new Date(new Date().getTime() + 86400000).toISOString().substring(0, 10),
      listPerPage: 8,
      offset: 0,
      pagecount: 0,
      loading: false
    };

    this.load = this.load.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  load() {
    this.loadData();
  }

  clearFilter() {
    this.setState({
      sdate: new Date(2022, 1, 15, 0, 0, 0).toISOString().substring(0, 10),
      edate: new Date(new Date().getTime() + 86400000).toISOString().substring(0, 10),
    }, () => this.loadData());
  }

  handlePageClick(e) {
    this.setState({
      offset: e.selected
    });
    this.loadData();
  }

  handleChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }

  async loadData() {
    try {
      if (localStorage.getItem("jwttoken") === null)
        return this.props.history.push('/login');
      // console.log(localStorage.getItem("jwttoken"));
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
      }

      const {
        sdate,
        edate
      } = this.state;

      this.setState({
        loading: true
      });

      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/user/sales`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          sdate,
          edate
        }),
      });

      const result = await res.json();
      // console.log(result);
      let limitResult = result.slice(this.state.offset*this.state.listPerPage, this.state.offset*this.state.listPerPage+this.state.listPerPage);
      console.log(limitResult);
      this.setState({
        sales: limitResult,
        loading: false,
        pagecount: Math.ceil(result.length / this.state.listPerPage)
      });
    }
    catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.loadData();
  }

 
  
  render() {

    let num = 0;

    let sales = [];

    const roles = <>
                    <option  value="admin">Admin</option>
                    <option value="mentor">Mentor</option>
                  </>;

    try {
      sales = this.state.sales.map((sale) => (
        <SalesMentorRow loadData={this.load} num={++num} roles={roles} key={sale._id} sale={sale} />
      ));
    } catch (err) {
      console.log(err);
    }

    return (
      <>
        <SideNavigation />
        <main id="content" className="p-5">

          <Form className='mb-4'>
            <h2>Filters</h2>
            <Row>
              <Form.Group as={Col} md={4}>
                <Form.Label>Select Start Date</Form.Label>
                <Form.Control type="date" name="sdate" value={this.state.sdate} onChange={this.handleChange} />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label>Select End Date</Form.Label>
                <Form.Control type="date" name="edate" value={this.state.edate} onChange={this.handleChange} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={this.handleSubmit} className="" size="sm" variant='dark' type="submit">
                  Apply Filter
                </Button>
              </Form.Group>
              <Form.Group as={Col} xs={2} className="mt-2">
                <Button onClick={this.clearFilter} className="" size="sm" variant='danger'>
                  Reset Filter
                </Button>
              </Form.Group>
            </Row>
          </Form>

          <MDBTable hover className='text-center' responsive>

            <MDBTableHead color="blue" textWhite>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Change Role</th>
                <th>Remove User</th>
              </tr>
            </MDBTableHead>

            <MDBTableBody>
              {(this.state.sales.length === 0 && !this.state.loading)?<tr><td>No Data Found</td></tr>:sales}
            </MDBTableBody>

          </MDBTable>
          
          {this.state.loading?
          <div className='text-center mb-3'>
            <Spinner animation="border" />
          </div>:null}

          < ReactPaginate
            previousLabel={"<<" }
            nextLabel={ ">>" }
            breakLabel={ "..." }
            breakClassName={ "break-me" }
            pageCount={ this.state.pagecount }
            onPageChange={ this.handlePageClick }
            containerClassName={ "pagination justify-content-center" }
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            nextClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextLinkClassName={'page-link'}
            activeClassName={ "active bg-primary" }
          />
        </main>
      </>
    );
  }
}

export default Sales;