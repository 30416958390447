import React, { useEffect, useState } from 'react';
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
// import { MDBCardText } from 'mdbreact';
import { toast } from 'react-toastify';

const AdminCardSection1 = () => {

  const [m, setM] = useState(null),
    [s, setS] = useState(null),
    [st, setSt] = useState(null),
    [pd, setPd] = useState(null),
    history = useHistory();

  useEffect(() => {
    try {
      const loadData = async () => {
        if (localStorage.getItem("jwttoken") === null)
          return history.push('/login');
        // console.log(localStorage.getItem("jwttoken"));
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("jwttoken")}`,
        }
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/length/sizes`, {
          method: 'GET',
          headers
        });
        const result = await res.json();
        // console.log(result);
        if (result) {
          setM(result.m);
          setS(result.s);
          setSt(result.st);
          setPd(result.pd);
        }
        else {
          setM(0);
          setS(0);
          setSt(0);
          setPd(0);
          toast.error('Something went Wrong!');
        }
      }
      loadData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <MDBRow className="mb-4">
      <MDBCol xl="3" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <MDBIcon icon="money-bill-alt" className="primary-color" />
            <div className="data">
              <p>All Users</p>
              <h4>
                <strong>{st}</strong>
              </h4>
            </div>
          </div>
          <MDBCardBody>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg-primary" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            {/* <MDBCardText>Better than last week (25%)</MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <MDBIcon icon="chart-line" className="warning-color" />
            <div className="data">
              <p>All Orders</p>
              <h4>
                <strong>{pd}</strong>
              </h4>
            </div>
          </div>
          <MDBCardBody>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg grey" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            {/* <MDBCardText>Worse than last week (25%)</MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <MDBIcon icon="chart-pie" className="light-blue lighten-1" />
            <div className="data">
              <p>Mentors</p>
              <h4>
                <strong>{m}</strong>
              </h4>
            </div>
          </div>
          <MDBCardBody>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar grey darken-2" role="progressbar"
                style={{ width: '75%' }}></div>
            </div>
            {/* <MDBCardText>Worse than last week (75%)</MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol xl="3" md="6" className="mb-r">
        <MDBCard className="cascading-admin-card">
          <div className="admin-up">
            <MDBIcon icon="chart-bar" className="red accent-2" />
            <div className="data">
              <p>Sales Person</p>
              <h4>
                <strong>{s}</strong>
              </h4>
            </div>
          </div>
          <MDBCardBody>
            <div className="progress">
              <div aria-valuemax="100" aria-valuemin="0" aria-valuenow="25" className="progress-bar bg-primary" role="progressbar"
                style={{ width: '25%' }}></div>
            </div>
            {/* <MDBCardText>Better than last week (25%)</MDBCardText> */}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      {!(m === null || s === null || st === null || pd === null) ?
        null :
        <div className='text-center mb-3 mt-5 w-100'>
          <Spinner animation="border" />
        </div>
      }
    </MDBRow>
  )
}

export default AdminCardSection1;

